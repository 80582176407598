import React from 'react';
import styled from 'styled-components';

const backgroundImage = require('../../assets/background.jpg');
const ContentImage = require('../../assets/content.png');
const Background = styled.div`
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: right bottom;
  margin: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
`;

const Info = styled.div`
  background-color: white;
  background: rgba(256, 256, 256, 0.2);
  max-width: 600px;
  width: 80vw;
  height: 300px;
  margin-top: 100px;
  font-size: 24px;
  font-weight: 300;
  text-align: left;
  display: flex;
  justify-content: center;
  align-content: center;
`;

const Email = styled.h4`
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  margin-bottom: 5px;
`;

export default function HelloScreen() {
  return (
    <Background>
      <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
        <Info>
          {/* <img src={ContentImage} /> */}
          <div style={{ marginTop: 10 }}>
            <ul>
              <ListItem>Content Management Systems</ListItem>
              <ListItem>Mobile Applications</ListItem>
              <ListItem>Digital Signs</ListItem>
            </ul>
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginTop: 50,
                fontSize: 20,
                alignItems: 'center'
              }}
            >
              <span>inquiries:</span>
              <Email>hello@duckduckgoose.ca</Email>
            </div>
          </div>
        </Info>
      </div>
    </Background>
  );
}
