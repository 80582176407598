import React from 'react';
import styled from 'styled-components';
import MenuIcon from '@material-ui/icons/Menu';

const Container = styled.div`
  background-color: #282c33;
  min-height: 60px;
  max-height: 60px;
`;

const Title = styled.div`
  text-align: center;
  color: #fff;
  font-size: 2em;
  font-weight: 200;
`;

const Image = styled.div`
  margin: 10px;
`;

export default function Header({ onMenuPressed, onProfilePressed }) {
  return (
    <Container
      style={{
        position: 'absolute',
        width: '100vw',
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        opacity: 0.7
      }}
    >
      <Image style={{ flex: 1, textAlign: 'left' }}>
        {onMenuPressed ? <MenuIcon fontSize='large' style={{ color: 'white' }} onClick={onMenuPressed} /> : null}
      </Image>
      <Title style={{ flex: 9, displaiy: 'flex', flexDirection: 'column', justifyItems: 'center' }}>
        duckduck<b>goose</b>
      </Title>
      <div style={{ flex: 1 }} />
    </Container>
  );
}
