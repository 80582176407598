import React from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './App.css';

import Header from './main/component/Header';
import NavigationMenu from './main/container/NavigationMenu';
import { ActionRoutes } from './main/navigation';

import { UserContext } from './main/context/UserContext';

function App() {
  const dispatch = useDispatch();

  const onMenuPressed = () => {
    dispatch({ type: 'TOGGLE_MENU' });
  };
  const onMenuClosed = () => {
    dispatch({ type: 'RESET_NAVIGATION' });
  };

  return (
    <div className='App'>
      <Header onMenuPressed={onMenuPressed} />
      <UserContext.Provider value={{}}>
        <BrowserRouter>
          <NavigationMenu onClosed={onMenuClosed} />
          <ActionRoutes />
        </BrowserRouter>
      </UserContext.Provider>
    </div>
  );
}

export default App;
