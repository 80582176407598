import React from 'react';

import HelloScreen from '../screens/Hello';
import HelloIcon from '../../assets/hello.png';

const config = {
  navigation: {
    items: [
      {
        title: 'Hello',
        path: '/hello',
        icon: <img src={HelloIcon} width={30} height={30} />
      }
    ]
  },
  routes: [
    {
      path: '/hello',
      main: () => <HelloScreen />
    }
  ],
  defaultPath: '/hello'
};

export default config;
